<template>
    <div class="menu">
        <div class="col-logo">
            <router-link to="/" class="logo"><svg><use xlink:href="#logo" /></svg></router-link>
        </div>
        <div class="col-nav">
            <router-link to="/plant">{{$t('nav-reports')}}</router-link>
            <router-link to="/about">{{$t('nav-about')}}</router-link>
            <router-link to="/contacts">{{$t('nav-contacts')}}</router-link>
            <div class="lang-select">
                <div :class="[lang == 'ru' ? 'active' : '']" @click="setLocale('ru')">RU</div>
                <div :class="[lang == 'en' ? 'active' : '']" @click="setLocale('en')">EN</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';
        return {
            lang: lang
        }
    },
    computed: {
        pageTitle: function() {
            return this.$route.meta.title;
        }
    },
    methods: {
        setLocale(locale) {
            localStorage.setItem('lang', locale)
            this.$i18n.locale = locale
            this.lang = locale
            window.location.reload()
            document.title = this.pageTitle
            console.log(this.pageTitle);
        }
    }
}
</script>

<style lang="less" scoped>
.lang-select {
    display: flex;
    & > div {
        font-size: 16px;
        padding: 2px 6px;
        border-radius: 3px;
        color: rgba(0,0,0,0.5);
        cursor: pointer;
    }
    .active {
        color: #fff;
        background: #000;
    }
}
.router-link-exact-active {
    text-decoration: none;
    color: #000;
}
</style>