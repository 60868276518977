<template>
  <div id="app">
    <icon-system />
    <nav-header />
    <router-view/>
    <app-footer />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader'
import IconSystem from '@/components/IconSystem'
import AppFooter from '@/components/AppFooter'
export default {
  components: {
    NavHeader,
    IconSystem,
    AppFooter
  },
  mounted() {
    if (location.protocol !== 'https:') {
      location.replace('https://dobroedelo39.ru');
    }
  }
}
</script>
