import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import SvgIcon from '@/components/SvgIcon'
Vue.component('svg-icon', SvgIcon);

import '@/assets/styles/global.css'

Vue.config.productionTip = false

const DEFAULT_TITLE = 'Some Default Title';
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        console.log(from);
    });
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
