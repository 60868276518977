<template>
  <div class="home">
    <div class="header">
      <h1>{{ $t('lesovosstanovlenie') }}</h1>

      <div class="header-cta">
        <a href="#donate" class="btn">{{$t('plant-a-tree')}}</a>
      </div>

      <div class="trees-planted">
        <div class="title">{{$t('blagodarya-vam')}}</div>
        <div>{{$t('my-posadili-uzhe')}} <span class="counter">{{ treeCount }}</span> {{$t('derevo')}}</div>
      </div>
    </div>

    <!-- <div class="noqiwi">{{$t('noqiwi')}}</div> -->

    <div id="donate" class="section sec-donate">
      <div class="hdg1 tac">{{$t('vnesti-pozhertvovanie')}}</div>
      <p v-html="$t('donate')"></p>
      <div class="tac"><svg-icon class="icon" icon="icon-tree" /></div>
      <form method="GET" action="/other/donate.php" class="hbox alic just-cn form-donate">
        <div class="pay-input">
          <div class="currency">$</div>
          <input v-model="donateSum" type="number" value="1" name="sum" id="">
        </div>
        <button class="btn btn-pay">{{$t('vnesti')}}</button>
      </form>
    </div>

    <div class="section sec-why">
      <div class="img-title">
        <div class="title">{{$t('zachem-sazhat-derevya')}}</div>
        <div class="subtitle">{{$t('kakova-polza-dlya-nashei-planety')}}</div>
      </div>

      <div class="wlimit">
        <div class="hdg1">{{$t('pochemu-derevya-tak-vazhny-dlya-okruzhayushei-sredy')}}</div>
        <p>
          {{$t('derevya-pomogayut-ochishat-vozdukh')}}
        </p>
        <p>
          {{$t('vot-shest-faktov-kotorye-obyasnyayut-pochemu-derevya-tak-vazhny')}}
        </p>

        <app-paragraphs />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AppParagraphs from '../components/AppParagraphs.vue'
  export default {
    name: 'Home',
    data() {
      return {
        donateSum: '1',
        treeCount: 0,
      }
    },
    mounted() {
      axios.get('https://dobroedelo39.ru/other/treecount.php').then((data)=>{
        this.treeCount = data.data;
        console.log('trees'+data.data);
      })
    },
    components: {
      AppParagraphs
    }
  }
</script>

<style lang="less" scoped>
.form-donate {margin-top: 16px;}
.sec-donate {
  padding-top: 6rem;
  .icon {
    width: 34px;
    height: 34px;
  }
  .hdg1 + .tac {
    margin-top: 16px;
    color: #107E7B;
  }
}
.noqiwi {
  text-align: center;
  color: red;
  font-size: 18px;
  position: relative;
  top: 60px;
}
</style>