<template>
    <div id="paragraphs" class="paragraphs">
        <div class="imgp" v-for="(p, index) in paragraphs" :key="index">
            <div class="col-img"><img :src="getImgUrl(p.img)" alt=""></div>
            <div class="col-text">
                <div class="title">{{ p.title }}</div>
                <div class="desc" v-html="p.desc"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                paragraphs: [{
                        img: 'why-air.jpg',
                        title: this.$t('air'),
                        desc: this.$t('prg-air')
                    },
                    {
                        img: 'why-water.jpg',
                        title: this.$t('why-water'),
                        desc: this.$t('why-water-desc')
                    },
                    {
                        img: 'why-bio.jpg',
                        title: this.$t('why-bio'),
                        desc: `
                        <p>${this.$t('odno-derevo-mozhet-byt-domom')}</p>
                    `
                    },
                    {
                        img: 'why-social.jpg',
                        title: this.$t('why-social'),
                        desc: this.$t('ot-arboristov-do-lesozagotovitelei')
                    },
                    {
                        img: 'why-health.jpg',
                        title: this.$t('why-health'),
                        desc: this.$t('znaete-li-vy-chto-bolnye')
                    },
                    {
                        img: 'why-climate.jpg',
                        title: this.$t('why-climat'),
                        desc: this.$t('derevya-pomogayut-okhlazhdat')
                    },
                ]
            }
        },
        methods: {
            getImgUrl(pic) {
                return require('../assets/' + pic)
            }
        }
    }
</script>