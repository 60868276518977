import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '@/i18n.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: i18n.t('title.home')}
  },
  {
    path: '/about',
    name: 'About',
    meta: {title: i18n.t('title.about')},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {title: i18n.t('title.contacts')},
    component: () => import('../views/Contacts.vue')
  },
  {
    path: '/plant',
    name: 'Plant',
    meta: {title: i18n.t('title.reports')},
    component: () => import('../views/Plant.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
